import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import AdjustableBaseRemoteControl from '../../src/AdjustableBaseRemoteControl';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "adjustablebaseremotecontrol"
    }}>{`AdjustableBaseRemoteControl`}</h1>
    <h2 {...{
      "id": "install"
    }}>{`Install`}</h2>
    <p>{`This component has some 3rd party dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add use-long-press
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import AdjustableBaseRemoteControl from '@mfrm/mfcl/AdjustableBaseRemoteControl'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Adjustable Bases remote control component.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={AdjustableBaseRemoteControl} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Component initialState={{}}>\n  {({ setState, state }) => (\n    <AdjustableBaseRemoteControl\n      style={{ width: 240 }}\n      features={{\n        head: true,\n        feet: true,\n        zeroGravity: true,\n        flat: true,\n      }}\n      onButtonTap={button => {\n        console.log(\'onButtonTap\', button)\n      }}\n      onButtonHold={button => {\n        console.log(\'onButtonHold\', button)\n      }}\n      onButtonRelease={button => {\n        console.log(\'onButtonRelease\', button)\n      }}\n    />\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      AdjustableBaseRemoteControl,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{}} mdxType="Component">
    {({
          setState,
          state
        }) => <AdjustableBaseRemoteControl style={{
          width: 240
        }} features={{
          head: true,
          feet: true,
          zeroGravity: true,
          flat: true
        }} onButtonTap={button => {
          console.log('onButtonTap', button);
        }} onButtonHold={button => {
          console.log('onButtonHold', button);
        }} onButtonRelease={button => {
          console.log('onButtonRelease', button);
        }} mdxType="AdjustableBaseRemoteControl" />}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      